import axios from "axios";
import store from "../store";
import router from "../router";
import Cookies from "js-cookie";
import { BModal } from "bootstrap-vue";

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

axios.interceptors.request.use(
    function (config) {
        const token = Cookies.get("access_token");
        if (token) config.headers.common["Authorization"] = "Bearer " + token;
        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status == 401) {
            Cookies.remove("access_token");
            store.dispatch("setToken", "");

            if (router.history.current.meta.requiresAuth) {
                router.push({
                    name: "auth-login",
                    query: { next: router.history.current.fullPath },
                });
            }
        } else if (error.response.status == 403) {
            if (
                error.response.data.message ==
                "Your email address is not verified."
            ) {
                router.push({ name: "auth-verify" });
            } else if (
                error.response.data.message == "Your account has been banned."
            ) {
                Cookies.remove("access_token");
                store.dispatch("setToken", "");
                router.push({ name: "banned" });
            }
        } else if (error.response.status == 404) {
            router.push({ name: "error-404" });
        } else if (error.response.status == 503) {
            router.push({ name: "under-maintenance" });
        } else {
            if (error.response.data.message != undefined) {
                new BModal().$bvModal.msgBoxOk(error.response.data.message, {
                    title: "Error",
                    okVariant: "danger",
                });
            }
            return Promise.reject(error);
        }
    }
);
