import axios from "axios";
export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        redeemVoucher({}, voucher) {
            return axios.post("user/redeem_voucher", {
                voucher: voucher,
            });
        },
    },
};
