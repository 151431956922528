import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import "../axios";
import auth from "./auth";
import user from "./user";
import status from "./status";
import downloader from "./downloader";
import plans from "./plans";
import payment_methods from "./payments/methods";
import payment_discount from "./payments/discount";
import payment_create from "./payments/create";
import payment_history from "./payments/history";
import login_history from "./login_history";
import voucher from "./voucher";
import resellers from "./resellers";
import support from "./support";
import notifications from "./notifications";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        auth,
        user,
        status,
        downloader,
        plans,
        voucher,
        payment_methods,
        payment_discount,
        payment_create,
        payment_history,
        login_history,
        resellers,
        support,
        notifications,
    },
    strict: process.env.DEV,
});
