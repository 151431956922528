import axios from "axios";
export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        contact({}, data) {
            return axios.post("app/contact", data);
        },
    },
};
