export default [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue"),
    },
    {
        path: "/status",
        name: "status",
        component: () => import("@/views/status/Status.vue"),
    },
    {
        path: "/plans",
        name: "plans",
        component: () => import("@/views/plans/Plans.vue"),
    },
    {
        path: "/support",
        name: "support",
        component: () => import("@/views/support/Support.vue"),
    },
    {
        path: "/terms",
        name: "terms",
        component: () => import("@/views/terms/Terms.vue"),
    },
    {
        path: "/privacy",
        name: "privacy",
        component: () => import("@/views/privacy/Privacy.vue"),
    },
    {
        path: "/resellers",
        name: "resellers",
        component: () => import("@/views/resellers/Resellers.vue"),
    },
    {
        path: "/resellers/panel",
        name: "resellers-panel",
        component: () => import("@/views/resellers/ResellerPanel.vue"),
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/Profile.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/downloader",
        name: "downloader",
        component: () => import("@/views/downloader/Downloader.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/download/:hash",
        name: "download",
        component: () => import("@/views/downloader/Download.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/history",
        name: "download-history",
        component: () => import("@/views/downloader/History.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/voucher",
        name: "voucher",
        component: () => import("@/views/voucher/RedeemVoucher.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/premium/:plan_id/:payment_id",
        name: "payment-summary",
        component: () => import("@/views/payment/PaymentSummary.vue"),
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: "/payment/success",
        name: "payment-success",
        component: () => import("@/views/payment/PaymentSuccess.vue"),
    },
    {
        path: "/payment/failed",
        name: "payment-failed",
        component: () => import("@/views/payment/PaymentFailed.vue"),
    },
];
