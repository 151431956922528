import store from "@/store";
export default [
    {
        path: "/login",
        name: "auth-login",
        component: () => import("@/views/auth/Login.vue"),
        meta: {
            layout: "full",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: "/register",
        name: "auth-register",
        component: () => import("@/views/auth/Register.vue"),
        meta: {
            layout: "full",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: "/forgot",
        name: "auth-forgot",
        component: () => import("@/views/auth/Forgot.vue"),
        meta: {
            layout: "full",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: "/reset/:token",
        name: "auth-reset",
        component: () => import("@/views/auth/ResetPassword.vue"),
        meta: {
            layout: "full",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: "/verify",
        name: "auth-verify",
        component: () => import("@/views/auth/Verify.vue"),
        meta: {
            layout: "full",
        },
    },
    {
        path: "/verify/email/:id/:hash",
        name: "verify-email",
        component: () => import("@/views/auth/VerifyEmail.vue"),
        meta: {
            requiresAuth: true,
            layout: "full",
        },
    },
    {
        path: "/logout",
        name: "auth-logout",
        meta: {
            requiresAuth: true,
        },
        beforeEnter(to, from) {
            store.dispatch("logout");
        },
    },
];
