import axios from "axios";
export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchResellers() {
            return axios.get("app/resellers");
        },
        loginReseller({}, query) {
            return axios.post("app/resellers/panel/login", query);
        },
        showVouchers({}, query) {
            return axios.post("app/resellers/panel/vouchers", query);
        },
        generateVouchers({}, query) {
            return axios.post("app/resellers/panel/vouchers/generate", query);
        },
    },
};
