export default [
    {
        path: "/not-authorized",
        name: "not-authorized",
        component: () => import("@/views/error/NotAuthorized.vue"),
        meta: {
            layout: "full",
        },
    },
    {
        path: "/banned",
        name: "banned",
        component: () => import("@/views/error/Banned.vue"),
        meta: {
            layout: "full",
        },
    },
    {
        path: "/error-404",
        name: "error-404",
        component: () => import("@/views/error/Error404.vue"),
        meta: {
            layout: "full",
        },
    },
    {
        path: "/wrong-ip-download",
        name: "wrong-ip-download",
        component: () => import("@/views/error/WrongIpDownload.vue"),
        meta: {
            layout: "full",
        },
    },
    {
        path: "/under-maintenance",
        name: "under-maintenance",
        component: () => import("@/views/error/UnderMaintenance.vue"),
        meta: {
            layout: "full",
        },
    },
    {
        path: "*",
        redirect: "error-404",
    },
];
