import axios from "axios";
export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchNotifications() {
            return axios.get("user/notifications");
        },
        markAsRead() {
            return axios.post("user/notifications/mark-as-read");
        },
    },
};
