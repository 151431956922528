import axios from "axios";
export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        historyPayment({}, page) {
            return axios.get("user/payments?page=" + page);
        },
    },
};
