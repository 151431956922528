import axios from "axios";
import Cookies from "js-cookie";

export default {
    state: {
        token: Cookies.get("access_token") || "",
    },
    getters: {
        isLoggedIn: (state) => !!state.token,
    },
    mutations: {
        SET_TOKEN: (state, token) => (state.token = token),
    },
    actions: {
        setToken: ({ commit }, token) => {
            if (token !== "") {
                Cookies.set("access_token", token, {
                    expires: 7,
                    sameSite: "strict",
                });
            }
            commit("SET_TOKEN", token);
        },
        logout: ({ commit }) => {
            axios
                .post("auth/logout")
                .then((res) => {
                    if (res.data.success) {
                        Cookies.remove("access_token");
                        commit("setToken", "");
                        window.location.href = "/";
                    }
                })
                .catch((err) => console.log(err));
        },
    },
};
