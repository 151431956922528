import axios from "axios";
export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchStatusHost() {
            return axios.get("app/status");
        },
    },
};
