import axios from "axios";
export default {
    state: {
        user: [],
    },
    getters: {
        getUser: (state) => state.user,
    },
    mutations: {
        SET_USER: (state, user) => (state.user = user),
    },
    actions: {
        fetchUser({ commit }) {
            return axios
                .get("user/account")
                .then((res) => {
                    commit("SET_USER", res.data.data);
                })
                .catch((err) => console.log(err));
        },
        updateProfile({}, data) {
            return axios.post("user/update_profile", {
                name: data.name,
                current_password: data.current_password,
                new_password: data.new_password,
                new_password_confirmation: data.new_password_confirmation,
            });
        },
    },
};
