import axios from "axios";
export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchPaymentMethods() {
            return axios.get("app/payment/methods");
        },
    },
};
