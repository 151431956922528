import axios from "axios";
export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        createPayment({}, query) {
            return axios.post("user/payment/create", query);
        },
    },
};
