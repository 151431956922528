import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import auth from "./auth";
import error from "./error";
import main from "./main";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [...main, ...auth, ...error],
});

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.getters.isLoggedIn;
    if (!isLoggedIn && to.meta.requiresAuth) {
        return router.push({
            name: "auth-login",
            query: { next: to.fullPath },
        });
    } else if (isLoggedIn && to.meta.redirectIfLoggedIn) {
        window.location.href = "/";
        return;
    } else {
        return next();
    }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;
