import axios from "axios";
export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        genVerify({}, response) {
            return axios.post("user/generator/verify", {
                "h-captcha-response": response,
            });
        },
        currentLimitHost() {
            return axios.get("user/limits");
        },
        fetchBandwidth() {
            return axios.get("user/bandwidth");
        },
        generator({}, data) {
            return axios.post("user/generator", {
                url: data.url,
                password: data.password,
            });
        },
        historyDownload({}, page) {
            return axios.get("user/downloads?page=" + page);
        },
        linkDownload({}, hash) {
            return axios.get("user/download/" + hash);
        },
    },
};
