import axios from "axios";
export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        historyLogin({}, page) {
            return axios.get("user/signed-in-logs?page=" + page);
        },
    },
};
